var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',[_vm._v("Liste des points")]),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red lighten-2","dark":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" Ajouter un point ")])]}}]),model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[(_vm.dialog2)?_c('DialogAddPoint',{attrs:{"close":function () {_vm.dialog2 = false;}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red lighten-2","dark":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" Rechercher ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('Search',{attrs:{"departements":_vm.departements,"parentSearch":_vm.parentSearch,"close":function () {_vm.dialog = false;}}})],1),(_vm.filtersSearch > 0)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.loadPoints(1);}}},[_vm._v("Effacer les critères")]):_vm._e(),_c('v-select',{attrs:{"outlined":"","label":"Departement","items":_vm.departements,"item-text":function (x) { return x.code+' - '+x.nom; },"return-object":""},model:{value:(_vm.dpt),callback:function ($$v) {_vm.dpt=$$v},expression:"dpt"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.points,"loading":_vm.loading,"items-per-page":25,"footer-props":{
      showFirstLastPage: true,
      'disableItemsPerPage': true
    }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('showDialog', item)}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('showDialogEdit', item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"loading":_vm.deleting == item.id},on:{"click":function($event){return _vm.deleteDoc(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('DialogDetailPoint'),_c('DialogEditPoint')],1)}
var staticRenderFns = []

export { render, staticRenderFns }