<template>
    <v-container fluid class="ma-0 pa-3">
        <v-form v-if="p">
            
            <v-text-field v-model="p.name.fr" label="Nom (français)" placeholder="Nom (français)" />
            <v-textarea auto-grow v-model="p.description.fr" label="Description (français)" placeholder="Description (français)" />

                     <v-text-field v-model="p.name.en" label="Nom (anglais)" placeholder="Nom (anglais)" />
            <v-textarea auto-grow v-model="p.description.en" label="Description (anglais)" placeholder="Description (anglais)" />
           
           <v-subheader>Localisation</v-subheader>
           <v-text-field v-model="p.location.address.streetAddress"  label="Adresse (numéro et nom rue)"/>
           <v-text-field v-model="p.location.address.zipcode" label="Code postal" />
           <v-text-field v-model="p.location.address.city" label="Commune" />
            <v-text-field v-model="p.location.address.department" label="Département" />
           <v-text-field v-model="p.location.address.region" label="Région" />
            <v-text-field v-model="p.location.address.country" label="Pays" />
           <v-subheader>Géolocalisation</v-subheader>
           <v-row>
               <v-col col="6" >
<MglMap
@load="mapLoaded"
             :zoom="p.location.geolocation.lng ? 15:5"
    :style="{'width': '100%', 'height': '300px' }"
    :accessToken="accessToken"
    :mapStyle="mapStyle"
    :center="[p.location.geolocation.lng, p.location.geolocation.lat]"
  >
    <MglMarker @dragend="updateGeolocMarker" :draggable="editGeolocation" :coordinates="[p.location.geolocation.lng, p.location.geolocation.lat]" color="blue" />
  </MglMap>
               </v-col>
                <v-col cols="6" class="ml-3">
                    <v-switch v-model="editGeolocation" label="Modification la position" />
                    <v-text-field v-model="p.location.geolocation.lat" label="Latitude" />
                    <v-text-field v-model="p.location.geolocation.lng" label="Longitude" />
               </v-col>

           </v-row>
            <v-subheader>Contacts</v-subheader>
            <p>Ecrire la nouvelle valeur puis faire "entrée" pour valider une nouvelle valeur</p>
            <template v-for="(c,i) of Object.keys(p.contacts)">
                     <v-combobox :key="'input_'+i" multiple  chips :label="c" v-model="p.contacts[c]">

            </v-combobox>
            </template>
           <v-subheader>Types</v-subheader>
            <v-combobox multiple  chips v-model="p.types">

            </v-combobox>
              <v-subheader>Valeurs</v-subheader>
              <ul>
            <template v-for="(v,i) of p.values" >
                    <li class="d-flex align-center" :key="'valeur_'+i">
                        <span class="ma-3 text-capitalize">{{ i }} </span>
                        <v-text-field class="d-flex" type="number" v-model="p.values[i]" /></li>
            </template>
              </ul>

           <template v-for="(entity,i) of ['creator', 'publisher']" >
               <div :key="'header_'+i">
                    <v-subheader ><span class="text-capitalize">{{ entity }} </span></v-subheader>
               <v-text-field v-model="p[entity].id" label="Identifiant (DATATourisme)" />
                 <v-text-field v-model="p[entity].ref" label="Reférence Firebase (DATATourisme)" />
               </div>
              
           </template>
        </v-form>
    </v-container>
</template> 
<script>
import { MglMap, MglMarker } from "vue-mapbox";
import { GeoPoint } from 'firebase/firestore';



export default {
    props: ['point'],
    components: {
        MglMap,
        MglMarker
    },
    data: () => ({
        p: null,
        editGeolocation: false,
        accessToken:"pk.eyJ1Ijoic2ltb241MSIsImEiOiJjamg4dGUyZXkwNmxuM2NvNmFxN2p5cnpmIn0.G4Igc5WvjFjuIQ6UcuxLOQ", // your access token. Needed if you using Mapbox maps
        mapStyle: "mapbox://styles/mapbox/outdoors-v11",
    }),
    watch: {
        'p.location.geolocation': function(v, x) {
           
            if (x == undefined || v.lat != x.lat || v.lng != x.lng) {
                 let {lat, lng} = v;
                 this.p.g.geopoint = new GeoPoint(lat, lng);
            }
           
        }
    },
    methods: {
   
        mapLoaded(map) {
            console.log(map);
        },
        updateGeolocMarker($e) {
            let {lat, lng}  = $e.marker.getLngLat();
            this.p.location.geolocation = {lat: lat, lng: lng};
       
            //this.point.location.geolocation 
            //this.point.location.geolocation.longitude = lng;
           // this.$forceUpdate();
           //console.log($e.marker.getLngLat());
        }
    },
    mounted() {
        
       
        this.p = Object.assign({}, this.point);
        console.log(this.p);
    }
}
</script>