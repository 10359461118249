<template>
    <v-container>
<h2>Liste des points</h2>



<v-spacer />

<v-dialog
      v-model="dialog2"
      width="800"
    >
      <template v-slot:activator="{ on, attrs }">
         <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          text
        >
          Ajouter un point
        </v-btn>
      </template>
<DialogAddPoint v-if="dialog2"  :close="() => {dialog2 = false;}" />
</v-dialog>

<v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
         <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          text
        >
          Rechercher
        </v-btn>
      </template>
<Search :departements="departements" :parentSearch="parentSearch" :close="() => {dialog = false;}" />
</v-dialog>




<v-btn text v-if="filtersSearch > 0" @click="loadPoints(1);">Effacer les critères</v-btn>
<v-select v-model="dpt" outlined label="Departement" :items="departements" :item-text="(x) => { return x.code+' - '+x.nom; }" return-object>

</v-select>
<v-data-table
:headers="headers"
    :items="points"
    :loading="loading"
    :items-per-page="25"
    class="elevation-1"
    :footer-props="{
      showFirstLastPage: true,
      'disableItemsPerPage': true
    }"
  >
  <template v-slot:item.actions="{ item }" >
<v-icon
        small
        class="mr-2"
        @click="$emit('showDialog', item)"
      >
        mdi-eye
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="$emit('showDialogEdit', item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon @click="deleteDoc(item)" :loading="deleting == item.id">
        mdi-delete
      </v-icon>
  </template>
  </v-data-table>
 <DialogDetailPoint />
 <DialogEditPoint />
    </v-container>
</template>
<script>
import { collection, getDocs, getFirestore, query,   where, deleteDoc, doc  } from "firebase/firestore"; 
import DialogDetailPoint from './dialogDetail.vue';
import DialogEditPoint from './dialogEdit.vue';
import DialogAddPoint from './dialogAdd.vue';
import Search from './search.vue';
export default {
  name: 'App',

    components: { DialogDetailPoint, DialogEditPoint, Search, DialogAddPoint },
    methods: {
      async deleteDoc(item) {
          if (window.confirm('Etes-vous sûr de supprimer ce point "'+item.name.fr+'" ?')) {
            this.deleting = item.id;
            await deleteDoc(doc(this.db, "points", item.id));
            this.deleting = null;
            this.loadPoints(1);
          }
        
      } ,
      parentSearch(params) {
        this.loadPoints(params);
      },
        show(item) {
            this.$emit('showDialog', item);
        },
       
        async loadPoints(p = null) {
          this.loading = true;
            
            console.log(p);
            //let w = null;
            let first;
          let constraints = []
this.filtersSearch = Object.keys(p).length;
                  if (Object.keys(p).length > 0) {
                    constraints= Object.keys(p).map((m) => {

                      return where(m, '==', p[m]);
                    });
                    console.log(constraints);

                   //first = query(collection(db, "points"), where(k,'==', p[k]));
                   first = query(collection(this.db, "points"),...constraints);
                    const documentSnapshots = await getDocs(first);
             this.loading = false;
            this.points = documentSnapshots.docs.map((d) => { 
                console.log(d.data());
                return {...d.data(), id: d.id}; 
                });
                  }  else {
                      this.loading = false;
                    this.points = [];
                  }
                 
                 
            

            
          
           
            //console.log(this.points);
        
        }
    },
  watch: {
    dpt: function(v, x) {
      if (v !== x) {
        console.log(v);
        this.loadPoints({'location.address.department': v.nom});
      }
    }
  },
  data: () => ({
    departements: [],
    dpt: null,
      points: [],
      db: null,
      loading: false,
      page: 1,
      pageCount: null,
      deleting: null,
      filtersSearch: 0,
      dialog: false,
      dialog2: false,
      headers: [
          {text: 'Nom', value: 'name.fr'},
          {text: 'Commune', value: 'location.address.city'},
          {text: 'Département', value: 'location.address.department'},
          
          { text: 'Actions', value: 'actions', sortable: false },
          ]
  }),
  mounted() {
    fetch('https://geo.api.gouv.fr/departements').then((r) => r.json()).then((j) => {
      this.departements = j;
    });
    this.db = getFirestore();
      //this.loadPoints(1);
  }
}
</script>