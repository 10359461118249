<template>
    <v-card>
        <v-card-title>
            <h2>Recherche</h2>
        </v-card-title>
        <v-card-text>
            <v-form v-model="form">
                {{ searchForm }}
<v-row>
 <v-text-field outlined  class="flex ma-3" v-model="searchForm['location.address.city']" label="commune" />
<v-text-field outlined class="flex ma-3" v-model="searchForm['location.address.zipcode']" label="code postal" />
<v-select outlined class="flex ma-3" :items="departements"  item-value="nom"  :item-text="(x) => { return x.code+' - '+x.nom; }" v-model="searchForm['location.address.department']" label="département" />
<v-text-field outlined class="flex ma-3" v-model="searchForm['location.address.region']" label="région" />

    <v-text-field outlined class="flex ma-3" v-model="searchForm['name.fr']" label="nom" />
</v-row>
               
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn text @click="search">Rechercher</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props: ['parentSearch','close', 'departements'],
    data: ()=>({
        form: null,
        searchForm: {'location.address.city': '', 'location.address.zipcode': '', 'name.fr': '', 'location.address.department': '', 'location.address.region': ''}
    }),
    methods: {
        search(){
            let x = {};
            Object.keys(this.searchForm).map((m) => {
                if (this.searchForm[m].length > 0) {
                    x[m] = this.searchForm[m];
                }
            });
            this.searchForm =  {'location.address.city': '', 'location.address.zipcode': '', 'name.fr': ''};

            this.parentSearch(x)
            this.close();
            
        }   
    }
}
</script>