<template>
    <v-card  min-width="400" max-width="800"> 

        <v-card-title>
            <h2>Ajouter un point</h2>
            
        </v-card-title>
         <v-divider />
         <v-card-text>
             <form-point :point="point"/>
         </v-card-text>
          <v-row>  
 <v-btn class="ma-3" :loading="adding" block  @click.prevent="add">Créer</v-btn>
           </v-row>
       
    </v-card>
</template> 
<script>
import FormPoint from './form.vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as geofirestore from 'geofirestore';


export default {
    props: ['close'],
    components: {FormPoint },
    data: () => ({
        adding: false,
        point: {
            name: {fr: '', en: ''},
            g: {geopoint: null },
            description:  {fr: '', en: ''},
            location:{
                address: {
                    streetAddress: null,
                    zipcode: null,
                    city: null,
                    country: null,
                    department: null,
                    region: null
                },
                geolocation: {
                    lat: null,
                    lng: null
                }
            },
            COVID19InOperationConfirmed: null,
            COVID19OpeningPeriodsConfirmed: null,
            contacts: {
                emails: [],
                phones: [],
                websites: []
            },
            creator: {

            },
            datatourismeProperties: {

            },
            googlePlaceId: null,
            methods: [],
            publisher: {},
            pics: [],
            slugEN: '',
            slugFR: '',
            types: [],
            values: {gps: 5},
            wikiData: null



        }
    }),
    methods: {
        async add() {
            this.adding = true;
            if (process.env.NODE_ENV && process.env.NODE_ENV=='development') {
  var firebaseConfig = {
    apiKey: "AIzaSyDnqF8m8IVeQTmVBsQ3Iz9aNWh7L7Zdq68",
    authDomain: "gofrance-dev.firebaseapp.com",
    databaseURL: "https://gofrance-dev.firebaseio.com",
    projectId: "gofrance-dev",
    storageBucket: "gofrance-dev.appspot.com",
    messagingSenderId: "955856492053",
    appId: "1:955856492053:web:b5175d641ae61df83ae01c"
  };
  
} else {
  firebaseConfig = {
    apiKey: "AIzaSyBgW1MYSndZiWaCpgqOMiwPLyy8igcxdwk",
    authDomain: "gofrance-7eb17.firebaseapp.com",
    databaseURL: "https://gofrance-7eb17.firebaseio.com",
    projectId: "gofrance-7eb17",
    storageBucket: "gofrance-7eb17.appspot.com",
    messagingSenderId: "448361569742",
    appId: "1:448361569742:web:1251576e085db9d9"
  };
  
}
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const GeoFirestore = geofirestore.initializeApp(firestore);

const geocollection = GeoFirestore.collection('points');

            this.point.coordinates = new  firebase.firestore.GeoPoint(this.point.location.geolocation.lat, this.point.location.geolocation.lng);
            console.log(this.point);
            await geocollection.add(this.point);
            this.adding = false;
            this.close();
            //console.log(this.point);
        }
    }
}
</script>