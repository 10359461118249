<template>
    <v-dialog v-model="dialog" scrollable>
        <v-card v-if="point" min-width="400" max-width="600">
            <div>
  <v-card-title :style="{position: 'sticky'}">
               <h2 class="title"> {{ point.name.fr }}</h2>
               <v-spacer />
               <v-btn icon @click="dialog = false; point = null;"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle>  
                {{ point.id }}
            </v-card-subtitle>
            <v-divider/>
            </div>
          
            <v-card-text>
               <v-list>
                     <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold">Nom (FR)</v-list-item-title>
         <v-list-item-subtitle>{{ point.name.fr}}</v-list-item-subtitle>
      </v-list-item-content>
        
    </v-list-item>
    <v-list-item>
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold">Nom (EN)</v-list-item-title>
         <v-list-item-subtitle>{{ point.name.en}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold">Description (FR)</v-list-item-title>
         <p>{{ point.description.fr}}</p>
      </v-list-item-content>
    </v-list-item> 
    <v-list-item >
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold">Description (EN)</v-list-item-title>
         <p>{{ point.description.en}}</p>
      </v-list-item-content>
    </v-list-item>
    <v-list-item >
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold" >Adresse</v-list-item-title>
         <p v-html="address"></p>
      </v-list-item-content>
    </v-list-item>
     <v-list-item >
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold" >Location GPS</v-list-item-title>
         <p><a target="_blank" :href="'https://www.google.com/maps?q=loc:'+point.location.geolocation.lat+','+point.location.geolocation.lng+''">{{ point.location.geolocation.lat }} / {{ point.location.geolocation.lng }}</a></p>
      </v-list-item-content>
    </v-list-item>
    <v-list-item >
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold" >Méthodes et points</v-list-item-title>
         <p>
             <ul>   
                 <template v-for="(m,i) of point.methods">
                     <li :key="'method_'+i">
                         {{m.type}} {{ m.type== 'gps' ? 'rayon '+m.range+'m': ''}} /  {{ point.values[m.type]}} points 
                     </li>
                 </template>
             </ul>
         </p>
      </v-list-item-content>
    </v-list-item>
       <v-list-item >
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold" >Types</v-list-item-title>
         <p>
             {{ point.types.join(', ')}}
         </p>
      </v-list-item-content>
    </v-list-item>
       <v-list-item >
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold" >Slug(s)</v-list-item-title>
         <p>
             FR: {{ point.slugFR}} <br />
              EN: {{ point.slugEN}}
         </p>
      </v-list-item-content>
    </v-list-item>
     <v-list-item >
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold" >Contacts</v-list-item-title>
         <p>
            <ul>
                <template v-for="(c, i) of point.contacts">
                    <li :key="'contact_'+i" v-if="c.length > 0"> {{ c.join(", ") }}</li>
                </template>
            </ul>  
         </p>
      </v-list-item-content>
    </v-list-item>
    <v-list-item >
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold" >Créateur</v-list-item-title>
         <p>
            <a :href="point.creator.id" target="_blank">{{ point.creator.ref }}</a>
         </p>
      </v-list-item-content>
    </v-list-item>
<v-list-item >
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold" >Photos</v-list-item-title>
         <p>
           
             {{ point.pics }}

         </p>
      </v-list-item-content>
    </v-list-item>

      <v-list-item >
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold" >Wikidata</v-list-item-title>
         <p>
            <a :href="point.creator.id" target="_blank">{{ point.wikidata }}</a>
         </p>
      </v-list-item-content>
    </v-list-item>

    <v-list-item >
        <v-list-item-content>
        <v-list-item-title class="font-weight-bold" >Google Place Id</v-list-item-title>
         <p>
            <a :href="point.creator.id" target="_blank">{{ point.googlePlaceId }}</a>
         </p>
      </v-list-item-content>
    </v-list-item>




               </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data: () => ({
        dialog: false,
        point: null
    }),
    computed: {
        address: function() {
            return this.point.location.address.streetAddress+' '+this.point.location.address.zipcode+' '+this.point.location.address.city+' <br/> '
            + this.point.location.address.department+' '+this.point.location.address.region+' '+this.point.location.address.country;
        }
    },
  mounted() {

      this.$parent.$on('showDialog', (x) => {
          this.point = x;
          console.log(x);
          this.dialog = true;
      })
  }
}
</script>