<template>
  <v-dialog v-model="dialog" scrollable>
    <v-card v-if="point" min-width="400" max-width="650" class="pa-4">
      <div>
        <v-card-title :style="{ position: 'sticky' }">
          <h2 class="title">{{ point.name.fr }}</h2>
          <v-spacer />
          <v-btn
            icon
            @click="
              dialog = false;
              point = null;
            "
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-subtitle>
          {{ point.id }}
        </v-card-subtitle>
        <v-divider />
      </div>

      <v-card-text> 
          
          <FormPoint :point="point" />
           </v-card-text>
           <v-row>  
 <v-btn class="ma-3" :loading="editing" block  @click.prevent="edit">Modifier</v-btn>
           </v-row>
          
    </v-card>
  </v-dialog>
</template>
<script>
import FormPoint from './form.vue';
import { doc, getFirestore,  updateDoc } from "firebase/firestore"; 

export default {
    data: () => ({
        dialog: false,
        point: null,
        geoCollection: null,
        editing: false
    }),
    components: { FormPoint },
    computed: {
        address: function() {
            return this.point.location.address.streetAddress+' '+this.point.location.address.zipcode+' '+this.point.location.address.city+' <br/> '
            + this.point.location.address.department+' '+this.point.location.address.region+' '+this.point.location.address.country;
        }
    },
    methods: {
      async edit() {
        this.editing = true;
        let db = getFirestore();
        console.log(this.point);
        let ref = doc(db, "points", this.point.id);
        await updateDoc(ref, this.point);
        this.editing = false;
      }
    },
  mounted() {
    

      this.$parent.$on('showDialogEdit', (x) => {
          this.point = x;
          console.log(x);
          this.dialog = true;
      })
  }
}
</script>
                
           